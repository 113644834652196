













import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { BonusService } from '@/services/bonus-service';

@Component
export default class Manager extends Vue {
  @Prop({ required: true })
  private type!: any;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  @Inject(BonusService) private bonusService!: BonusService;

  private async fileChange(file: any) {
    console.log('Event', file); 
      let formData = new FormData();
      formData.append('file', file.raw);
      const loading = this.$loading({
          lock: true,
          text: '上传列表中，请稍后...'
      });
      try {
          const res = await  this.bonusService.uploadBackend(formData,this.type);        
            if (res) {
              this.$notify.success({
                title: '成功',
               message: '文件上传成功' + res.updateCount + '条数据已更新'
            });  
          }        
      } finally {
        loading.close();
      }

  }
}
