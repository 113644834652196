import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class BonusService {
  @Inject(HttpService) private http!: HttpService;

 //下载
  public downLoadAll(param: Dict<any>,filename:any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/pcImport/exportTemplate', param, filename);
  }
  //导出
  public exportReport(param: Dict<any>,filename:any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/reportFrom/exportReport', param, filename);
  }
  //上传
  public uploadBackend(param: Dict<any>, type: string): Promise<any> {
    let config: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    let queryString: any = {
      type:type
    };
    return this.http.post(
      '/wecom-bsp-backend/admin/pcImport/importExcel',
      param,
      queryString,
      config
    );
  }

  // 人员信息列表
  public infoMation(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/user/userList', param);
  }
  //组织架构调整
  public organizeList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/organizeStructure/organizeList', param);
  }
  //组织架构调整
  public productInfoList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/productInfo/productInfoList', param);
  }
  //组织架构调整
  public thisProductPriceList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/thisProductPrice/thisProductPriceList', param);
  }
  //指标&同期销售列表
  public stgTargetList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/stgTarget/stgTargetList', param);
  }
  //增长奖参数
  public novaGrowthBaseList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/novaGrowthBase/novaGrowthBaseList', param);
  }
  //达成率&支付比例列表
  public discountPaymentRatioList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/discountPaymentRatio/discountPaymentRatioList', param);
  }
  //去年全年达成率
  public lastFullDiscountList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/lastFullDiscount/lastFullDiscountList', param);
  }
  //档位区间
  public shiftIntervalList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/shiftInterval/shiftIntervalList', param);
  }
  //档位说明
  public proLevelExplainList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/proLevelExplain/proLevelExplainList', param);
  }
  //档位区间
  public novaArchBaseList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/novaArchBase/novaArchBaseList', param);
  }
  //奖金排行更新
  public bonusRankingList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/bonusRanking/bonusRankingList', param);
  }
  //管理员模拟权限配置
  public adminImpersonationList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/adminnistrator/adminImpersonationList', param);
  }
  //管理员模拟权限配置新增
  public addAdminImpersonation(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/adminnistrator/addAdminImpersonation', param);
  }
  //管理员模拟权限配置修改状态
  public updateAdminImpersonation(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/adminnistrator/updateAdminImpersonation', param);
  }
  //奖金报表
  public reportFromList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/reportFrom/reportFromList', param);
  }
  //职位下拉框
  public position(): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcDropDownList/position');
  }
  //france下拉框
  public franchise(): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcDropDownList/franchise');
  }
  //导出日志列表
  public importLogList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcImport/importLogList',param);
  }
  //查看失败原因
  public importLogById(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcImport/importLogById',param);
  }
  //公告提示列表
  public advertisementList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/advertisement/advertisementList',param);
  }
  //公告提示新增
  public addAdvertisement(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/advertisement/addAdvertisement',param);
  }
  //公告提示修改
  public updateAdvertisement(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/advertisement/updateAdvertisement',param);
  }
  //公告提示删除
  public deleteAdvertisement(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/advertisement/deleteAdvertisement',param);
  }
  //消息配置删除
  public deleteConfigMsg(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/configMsg/deleteConfigMsg',param);
  }
  //消息配置列表
  public configMsgList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/configMsg/configMsgList',param);
  }
  //消息配置新增
  public addConfigMsg(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/configMsg/addConfigMsg',param);
  }
  //消息配置修改
  public updateConfigMsg(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/configMsg/updateConfigMsg',param);
  }
  //奖项配置列表
  public awardConfigList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/awardConfiguration/awardConfigurationList',param);
  }
  //奖项配置更新
  public updateAwardConfig(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/awardConfiguration/updateAwardConfiguration',param);
  }
  //奖项配置新增
  public addAwardConfiguration(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/awardConfiguration/addAwardConfiguration',param);
  }
  //奖项配置删除
  public deleteAwardConfiguration(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/awardConfiguration/deleteAwardConfiguration',param);
  }
  //特定产品线系数表分页
  public productInfoCoefficientList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/productInfoCoefficient/productInfoCoefficientList',param);
  }
  //特定产品线系数表详情
  public getProductInfoCoefficient(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/productInfoCoefficient/getProductInfoCoefficient',param);
  }

  //特定产品线系数配置新增
  public addProductInfoCoefficient(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/productInfoCoefficient/addProductInfoCoefficient',param);
  }
  //特定产品线系数配置修改
  public updateProductInfoCoefficient(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/productInfoCoefficient/updateProductInfoCoefficient',param);
  }
  //特定产品线系数配置删除
  public deleteProductInfoCoefficient(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/productInfoCoefficient/deleteProductInfoCoefficient',param);
  }

  //档位品牌指标配置集合
  public novaArchTargetConfigList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/novaArchTargetConfig/list', param);
  }

  //奖项内容列表集合
  public awardsContentList(param: Dict<any>): Promise<any> {
    return this.http.post('wecom-bsp-backend/admin/awardsContent/page', param);
  }

  //奖项内容列表集合删除
  public awardsContentDelete(param: Dict<any>): Promise<any> {
    return this.http.post(`/wecom-bsp-backend/admin/awardsContent/delete/${param.id}`);
  }

  //奖项内容初始化
  public awardsContentDetail(param: Dict<any>): Promise<any> {
    return this.http.get(`/wecom-bsp-backend/admin/awardsContent/detail/${param.id}`);
  }

  //奖项内容列表搜索
  public awardsContentDetailList(param: Dict<any>): Promise<any> {
    let query = {...param};
    if (param && param.franchise) {
      query.franchise = encodeURIComponent(param.franchise);
    }
    return this.http.get('/wecom-bsp-backend/admin/awardsContent/detail/list', query);
  }

  //奖项内容新增
  public awardsContentCreate(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/awardsContent/create', param);
  }

  //奖项内容详情
  public awardsContentDetailListByID(param: Dict<any>): Promise<any> {
    return this.http.get(`/wecom-bsp-backend/admin/awardsContent/detail/list/${param.id}`);
  }

  //奖项内容更新
  public awardsContentDetailUpdate(param: Dict<any>): Promise<any> {
    return this.http.post(`/wecom-bsp-backend/admin/awardsContent/update`, param);
  }

  //奖项内容状态变更
  public awardsContentStatusChange(param: Dict<any>): Promise<any> {
    return this.http.post(`/wecom-bsp-backend/admin/awardsContent/statusChange`, param);
  }

  //奖项内容franchise校验
  public franchiseCheck(param: Dict<any>): Promise<any> {
    return this.http.post('wecom-bsp-backend/admin/awardsContent/franchise/check', param);
  }

  //奖项列表集合
  public awardsList(param: Dict<any>): Promise<any> {
    return this.http.post('wecom-bsp-backend/admin/awards/page', param);
  }

  //奖项详情
  public awardsDetail(param: Dict<any>): Promise<any> {
    return this.http.get('wecom-bsp-backend/admin/awards/detail', param);
  }

  //奖项新增
  public awardsCreate(param: Dict<any>): Promise<any> {
    return this.http.post('wecom-bsp-backend/admin/awards/create', param);
  }

  //奖项修改
  public awardsUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('wecom-bsp-backend/admin/awards/update', param);
  }

  //根据类型获取关联项目
  public awardsOptions(param: Dict<any>): Promise<any> {
    let query = {...param};
    if (param && param.franchise) {
      query.franchise = encodeURIComponent(param.franchise);
    }
    return this.http.get('wecom-bsp-backend/admin/awards/options', query);
  }

  //根据类型获取关联项目
  public awardsDelete(param: Dict<any>): Promise<any> {
    return this.http.post('wecom-bsp-backend/admin/awards/delete', param);
  }

  //上传Icon
  public posterUpload(param: Dict<any>, restConfig ?: any):Promise<any> {
    return this.http.post('/wecom-bsp-backend/admin/upload/icon', param, undefined, restConfig);
  }
}
